import { Box, Container, Image, Stack, Text } from "@chakra-ui/react";
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { GoBook } from "react-icons/go";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { teacherData } from "../../../staticData/teachers-data";

const Services = ({ t }) => {
  function SampleNextArrow(props) {
    return (
      <div
        style={{
          display: "flex",
          background: "#e4e8ebd9",
          width: "50px",
          height: "40px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          marginTop: 0,
        }}
        onClick={gotoNext}
      >
        <IoMdArrowForward color="white" fontWeight="700" fontSize="20px" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    return (
      <div
        style={{
          display: "flex",
          background: "#e4e8ebd9",
          width: "50px",
          height: "40px",

          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
        onClick={gotoPrev}
      >
        <IoMdArrowBack color="white" fontWeight="700" fontSize="20px" />
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    cssEase: "linear",
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <>
      <Container
        maxW={"container.xl"}
        my={16}
        id="teachers"
        position="relative"
      >
        <Text
          textAlign="center"
          fontWeight="500"
          fontSize={{ base: "20px", md: "25px" }}
          mb={3}
          // className="title-line"
        >
          {t("components.services.title")}{" "}
          <Image src={require("../../../assets/line.jpg")} m="auto" mt={3} />
        </Text>

        <Slider {...settings} ref={sliderRef}>
          {teacherData?.map((elem) => {
            return (
              <Box
                bg="#e4e8eb80"
                borderRadius="20px"
                minH="400px"
                p="20px"
                w={{ base: "90% !important", md: "350px" }}
                boxShadow="0px 1px 4px #8080804d"
              >
                <Image
                  borderRadius="20px"
                  src={elem?.image}
                  height="280px"
                  width="100%"
                  inset="0px"
                  objectFit="cover"
                  objectPosition="top"
                  color="transparent"
                />
                <Stack justify="space-between" mt={3}>
                  <Stack direction="row" alignItems="center">
                    <GoBook fontSize="18px" />
                    <Text fontSize="16px">{elem?.subject}</Text>
                  </Stack>
                  <Text fontSize="20px" fontWeight="600">
                    {elem?.name}
                  </Text>
                </Stack>
              </Box>
            );
          })}
        </Slider>
        <Stack
          flexDir="row"
          gap={6}
          position="absolute"
          top="55%"
          left="45%"
          display={{ base: "none", md: "flex" }}
        >
          <SamplePrevArrow />

          <SampleNextArrow />
        </Stack>
      </Container>
    </>
  );
};

export default withTranslation()(Services);
