import { Avatar, Button, HStack, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import bg from "../../../assets/icons/exams.svg";
import { examRedoRequest } from "../../../modules/students-modules/exams/Actions";

const ExamMainData = ({ exam, state, solveExamFun, checkExamAnswersFun }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  return (
    <VStack
      bg="main_1_light"
      borderRadius="8px"
      alignItems="flex-start"
      p="10px"
      spacing="20px"
      w="100%"
    >
      {/* title data */}
      <HStack>
        <Avatar
          name={exam?.name}
          src={bg}
          borderRadius="0"
          size="md"
          bg="transparent"
        />

        <VStack alignItems="flex-start">
          <Text fontWeight="500">{exam?.name}</Text>
          <Text opacity={"0.8"}>
            {dayjs(exam?.created_at).format("D-MM-YYYY")}{" "}
          </Text>
        </VStack>
      </HStack>

      <VStack color="main_1" w="100%">
        <HStack justifyContent="space-between" w="100%">
          <Text fontWeight="500">Status:</Text>
          <Text>{state}</Text>
        </HStack>

        {exam?.timer == 1 && (
          <HStack justifyContent="space-between" w="100%">
            <Text fontWeight="500">Exam time:</Text>
            <Text>{exam?.time} min </Text>
          </HStack>
        )}
        {exam?.total_count && (
          <HStack justifyContent="space-between" w="100%">
            <Text fontWeight="500">Number of questions:</Text>
            <Text>{exam?.total_count}</Text>
          </HStack>
        )}
      </VStack>
      {exam?.score && (
        <HStack justifyContent="space-between" w="100%">
          <Text fontWeight="500">Score:</Text>
          <Text>
            {exam?.right_answers == null ? "--" : exam?.right_answers} /{" "}
            {exam?.score}
          </Text>
        </HStack>
      )}
      {state == "Solved" ? (
        <HStack w="100%">
          <Button
            w="50%"
            bg="main_1"
            color="white"
            onClick={() => checkExamAnswersFun(exam)}
            isDisabled={exam?.correction == 1 ? false : true}
          >
            Check your answers
          </Button>
          <Button
            isLoading={isLoading}
            w="50%"
            border="1px solid"
            borderColor="main_1"
            color="main_1"
            onClick={() => {
              setLoading(true);
              let formData = new FormData();
              formData.append("exam_id", exam?.id);
              const action = () => {
                setLoading(false);
              };
              dispatch(examRedoRequest(formData, action));
            }}

            isDisabled={exam?.redo_flag == 1 ? false : true}
          >
            Request Redo
          </Button>
        </HStack>
      ) : (
        <Button
          w="100%"
          bg="main_1"
          color="white"
          isDisabled={exam?.enable == 0 ? false : true}
          onClick={() => {
            localStorage.setItem("startTime", new Date());
            solveExamFun(exam);
          }}
        >
          Start your exam
        </Button>
      )}
    </VStack>
  );
};

export default ExamMainData;
