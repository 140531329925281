import { Box, Container, Image, Stack, Text } from "@chakra-ui/react";
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { GoBook } from "react-icons/go";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PicOne from "../../../assets/tech-1.jpeg";
import PicTwo from "../../../assets/tech-2.jpeg";
import { teacherData } from "../../../staticData/teachers-data";

const LatestCourses = ({ t }) => {
  const data = [
    { pic: PicOne, subject: "chemistry", arabic: "كيمياء" },
    { pic: PicTwo, subject: "Arabic", arabic: "اللغة العربية" },
    { pic: PicTwo, subject: "Arabic", arabic: "اللغة العربية" },
    { pic: PicTwo, subject: "Arabic", arabic: "اللغة العربية" },
    { pic: PicTwo, subject: "Arabic", arabic: "اللغة العربية" },
    { pic: PicTwo, subject: "Arabic", arabic: "اللغة العربية" },
  ];

  function SampleNextArrow(props) {
    return (
      <div
        style={{
          display: "flex",
          background: "#e4e8ebd9",
          width: "50px",
          height: "40px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          marginTop: 0,
        }}
        onClick={gotoNext}
      >
        <IoMdArrowForward color="white" fontWeight="700" fontSize="20px" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    return (
      <div
        style={{
          display: "flex",
          background: "#e4e8ebd9",
          width: "50px",
          height: "40px",

          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
        onClick={gotoPrev}
      >
        <IoMdArrowBack color="white" fontWeight="700" fontSize="20px" />
      </div>
    );
  }
  const settings = {
    dots: false,
    // infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <Container maxW={"container.xl"} my={16} id="subjects" position="relative">
      <Text
        textAlign="center"
        fontWeight="500"
        fontSize={{ base: "20px", md: "25px" }}
        mb={8}
      >
        {t("components.latestCources.title")}{" "}
        <Image src={require("../../../assets/line.jpg")} m="auto" mt={3} />
      </Text>

      <Slider {...settings} ref={sliderRef}>
        {teacherData?.map((elem) => {
          return (
            <Stack
              flexDir="row"
              bg="#e4e8eb80"
              borderRadius="20px"
              display="flex !important"
              minH="130px"
              p="20px"
              w={{ base: "90% !important", md: "300px" }}
              justify="space-between"
              align="center"
              boxShadow="0px 1px 4px #8080804d"
            >
              <Box width="35%" h="70px">
                <Image
                  borderRadius="20px"
                  src={elem?.image}
                  h="100%"
                  w="100%"
                  objectFit="contain"
                  color="transparent"
                />
              </Box>

              <Stack w="60%">
                <Stack direction="row" alignItems="center">
                  <GoBook fontSize="18px" />
                  <Text fontSize="16px">{elem?.subject}</Text>
                </Stack>
                <Text>{elem?.subjectAr}</Text>
              </Stack>
            </Stack>
          );
        })}
      </Slider>
      <Stack
        flexDir="row"
        gap={6}
        position="absolute"
        top="65%"
        left="45%"
        display={{ base: "none", md: "flex" }}
      >
        <SamplePrevArrow />

        <SampleNextArrow />
      </Stack>
    </Container>
  );
};

export default withTranslation()(LatestCourses);
