export const actions = {
  CLOSE_MODEL_1: "CLOSE_MODEL_1",
  OPEN_MODEL_1: "OPEN_MODEL_1",
  CLOSE_MODEL_2: "CLOSE_MODEL_2",
  OPEN_MODEL_2: "OPEN_MODEL_2",
  CLOSE_MODEL_3: "CLOSE_MODEL_3",
  OPEN_MODEL_3: "OPEN_MODEL_3",
  CLOSE_MODEL_4: "CLOSE_MODEL_4",
  OPEN_MODEL_4: "OPEN_MODEL_4",
  CLOSE_MODEL_5: "CLOSE_MODEL_5",
  OPEN_MODEL_5: "OPEN_MODEL_5",
  CLOSE_MODEL_6: "CLOSE_MODEL_6",
  OPEN_MODEL_6: "OPEN_MODEL_6",
  CLOSE_MODEL_7: "CLOSE_MODEL_7",
  OPEN_MODEL_7: "OPEN_MODEL_7",
  CLOSE_MODEL_8: "CLOSE_MODEL_8",
  OPEN_MODEL_8: "OPEN_MODEL_8",
  CLOSE_MODEL_9: "CLOSE_MODEL_9",
  OPEN_MODEL_9: "OPEN_MODEL_9",
  CLOSE_MODEL_10: "CLOSE_MODEL_10",
  OPEN_MODEL_10: "OPEN_MODEL_10",
  CLOSE_MODEL_11: "CLOSE_MODEL_11",
  OPEN_MODEL_11: "OPEN_MODEL_11",
  CLOSE_MODEL_12: "CLOSE_MODEL_12",
  OPEN_MODEL_12: "OPEN_MODEL_12",
  CLOSE_MODEL_13: "CLOSE_MODEL_13",
  OPEN_MODEL_13: "OPEN_MODEL_13",
  CLOSE_MODEL_14: "CLOSE_MODEL_14",
  OPEN_MODEL_14: "OPEN_MODEL_14",
  CLOSE_MODEL_15: "CLOSE_MODEL_15",
  OPEN_MODEL_15: "OPEN_MODEL_15",
  CLOSE_MODEL_16: "CLOSE_MODEL_16",
  OPEN_MODEL_16: "OPEN_MODEL_16",
  CLOSE_MODEL_17: "CLOSE_MODEL_17",
  OPEN_MODEL_17: "OPEN_MODEL_17",
  CLOSE_MODEL_18: "CLOSE_MODEL_18",
  OPEN_MODEL_18: "OPEN_MODEL_18",
  CLOSE_MODEL_19: "CLOSE_MODEL_19",
  OPEN_MODEL_19: "OPEN_MODEL_19",
  CLOSE_MODEL_20: "CLOSE_MODEL_20",
  OPEN_MODEL_20: "OPEN_MODEL_20",
  CLOSE_MODEL_21: "CLOSE_MODEL_21",
  OPEN_MODEL_21: "OPEN_MODEL_21",
  CLOSE_MODEL_22: "CLOSE_MODEL_22",
  OPEN_MODEL_22: "OPEN_MODEL_22",
  CLOSE_MODEL_23: "CLOSE_MODEL_23",
  OPEN_MODEL_23: "OPEN_MODEL_23",
  CLOSE_MODEL_24: "CLOSE_MODEL_24",
  OPEN_MODEL_24: "OPEN_MODEL_24",
  CLOSE_MODEL_25: "CLOSE_MODEL_25",
  OPEN_MODEL_25: "OPEN_MODEL_25",
  CLOSE_MODEL_26: "CLOSE_MODEL_26",
  OPEN_MODEL_26: "OPEN_MODEL_26",
  CLOSE_MODEL_27: "CLOSE_MODEL_27",
  OPEN_MODEL_27: "OPEN_MODEL_27",
  CLOSE_MODEL_28: "CLOSE_MODEL_28",
  OPEN_MODEL_28: "OPEN_MODEL_28",
  CLOSE_MODEL_29: "CLOSE_MODEL_29",
  OPEN_MODEL_29: "OPEN_MODEL_29",
  CLOSE_MODEL_30: "CLOSE_MODEL_30",
  OPEN_MODEL_30: "OPEN_MODEL_30",
  OPEN_MODEL_32: "OPEN_MODEL_32",
  CLOSE_MODEL_32: "CLOSE_MODEL_32",
};

const initialState = {
  isOpen1: false,
  isOpen2: false,
  isOpen3: false,
  isOpen4: false,
  isOpen5: false,
  isOpen6: false,
  isOpen7: false,
  isOpen8: false,
  isOpen9: false,
  isOpen10: false,
  isOpen11: false,
  isOpen12: false,
  isOpen13: false,
  isOpen14: false,
  isOpen15: false,
  isOpen16: false,
  isOpen17: false,
  isOpen18: false,
  isOpen19: false,
  isOpen20: false,
  isOpen21: false,
  isOpen22: false,
  isOpen23: false,
  isOpen24: false,
  isOpen25: false,
  isOpen26: false,
  isOpen27: false,
  isOpen28: false,
  isOpen29: false,
  isOpen30: false,
  isOpen32: false,
};

export default function modelsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.OPEN_MODEL_1: {
      return {
        ...state,
        isOpen1: true,
      };
    }
    case actions.CLOSE_MODEL_1: {
      return {
        ...state,
        isOpen1: false,
      };
    }
    case actions.OPEN_MODEL_2: {
      return {
        ...state,
        isOpen2: true,
      };
    }
    case actions.CLOSE_MODEL_2: {
      return {
        ...state,
        isOpen2: false,
      };
    }
    case actions.OPEN_MODEL_3: {
      return {
        ...state,
        isOpen3: true,
      };
    }
    case actions.CLOSE_MODEL_3: {
      return {
        ...state,
        isOpen3: false,
      };
    }
    case actions.OPEN_MODEL_4: {
      return {
        ...state,
        isOpen4: true,
      };
    }
    case actions.CLOSE_MODEL_4: {
      return {
        ...state,
        isOpen4: false,
      };
    }
    case actions.OPEN_MODEL_5: {
      return {
        ...state,
        isOpen5: true,
      };
    }
    case actions.CLOSE_MODEL_5: {
      return {
        ...state,
        isOpen5: false,
      };
    }
    case actions.OPEN_MODEL_6: {
      return {
        ...state,
        isOpen6: true,
      };
    }
    case actions.CLOSE_MODEL_6: {
      return {
        ...state,
        isOpen6: false,
      };
    }
    case actions.OPEN_MODEL_7: {
      return {
        ...state,
        isOpen7: true,
      };
    }
    case actions.CLOSE_MODEL_7: {
      return {
        ...state,
        isOpen7: false,
      };
    }
    case actions.OPEN_MODEL_8: {
      return {
        ...state,
        isOpen8: true,
      };
    }
    case actions.CLOSE_MODEL_8: {
      return {
        ...state,
        isOpen8: false,
      };
    }
    case actions.OPEN_MODEL_9: {
      return {
        ...state,
        isOpen9: true,
      };
    }
    case actions.CLOSE_MODEL_9: {
      return {
        ...state,
        isOpen9: false,
      };
    }
    case actions.OPEN_MODEL_10: {
      return {
        ...state,
        isOpen10: true,
      };
    }
    case actions.CLOSE_MODEL_10: {
      return {
        ...state,
        isOpen10: false,
      };
    }
    case actions.OPEN_MODEL_11: {
      return {
        ...state,
        isOpen11: true,
      };
    }
    case actions.CLOSE_MODEL_11: {
      return {
        ...state,
        isOpen11: false,
      };
    }
    case actions.OPEN_MODEL_12: {
      return {
        ...state,
        isOpen12: true,
      };
    }
    case actions.CLOSE_MODEL_12: {
      return {
        ...state,
        isOpen12: false,
      };
    }
    case actions.OPEN_MODEL_13: {
      return {
        ...state,
        isOpen13: true,
      };
    }
    case actions.CLOSE_MODEL_13: {
      return {
        ...state,
        isOpen13: false,
      };
    }
    case actions.OPEN_MODEL_14: {
      return {
        ...state,
        isOpen14: true,
      };
    }
    case actions.CLOSE_MODEL_14: {
      return {
        ...state,
        isOpen14: false,
      };
    }
    case actions.OPEN_MODEL_15: {
      return {
        ...state,
        isOpen15: true,
      };
    }
    case actions.CLOSE_MODEL_15: {
      return {
        ...state,
        isOpen15: false,
      };
    }
    case actions.OPEN_MODEL_16: {
      return {
        ...state,
        isOpen16: true,
      };
    }
    case actions.CLOSE_MODEL_16: {
      return {
        ...state,
        isOpen16: false,
      };
    }
    case actions.OPEN_MODEL_17: {
      return {
        ...state,
        isOpen17: true,
      };
    }
    case actions.CLOSE_MODEL_17: {
      return {
        ...state,
        isOpen17: false,
      };
    }
    case actions.OPEN_MODEL_18: {
      return {
        ...state,
        isOpen18: true,
      };
    }
    case actions.CLOSE_MODEL_18: {
      return {
        ...state,
        isOpen18: false,
      };
    }
    case actions.OPEN_MODEL_19: {
      return {
        ...state,
        isOpen19: true,
      };
    }
    case actions.CLOSE_MODEL_19: {
      return {
        ...state,
        isOpen19: false,
      };
    }
    case actions.OPEN_MODEL_20: {
      return {
        ...state,
        isOpen20: true,
      };
    }
    case actions.CLOSE_MODEL_20: {
      return {
        ...state,
        isOpen20: false,
      };
    }
    case actions.OPEN_MODEL_21: {
      return {
        ...state,
        isOpen21: true,
      };
    }
    case actions.CLOSE_MODEL_21: {
      return {
        ...state,
        isOpen21: false,
      };
    }
    case actions.OPEN_MODEL_22: {
      return {
        ...state,
        isOpen22: true,
      };
    }
    case actions.CLOSE_MODEL_22: {
      return {
        ...state,
        isOpen22: false,
      };
    }
    case actions.OPEN_MODEL_23: {
      return {
        ...state,
        isOpen23: true,
      };
    }
    case actions.CLOSE_MODEL_23: {
      return {
        ...state,
        isOpen23: false,
      };
    }
    case actions.OPEN_MODEL_24: {
      return {
        ...state,
        isOpen24: true,
      };
    }
    case actions.CLOSE_MODEL_24: {
      return {
        ...state,
        isOpen24: false,
      };
    }
    case actions.OPEN_MODEL_25: {
      return {
        ...state,
        isOpen25: true,
      };
    }
    case actions.CLOSE_MODEL_25: {
      return {
        ...state,
        isOpen25: false,
      };
    }
    case actions.OPEN_MODEL_26: {
      return {
        ...state,
        isOpen26: true,
      };
    }
    case actions.CLOSE_MODEL_26: {
      return {
        ...state,
        isOpen26: false,
      };
    }
    case actions.OPEN_MODEL_27: {
      return {
        ...state,
        isOpen27: true,
      };
    }
    case actions.CLOSE_MODEL_27: {
      return {
        ...state,
        isOpen27: false,
      };
    }
    case actions.OPEN_MODEL_28: {
      return {
        ...state,
        isOpen28: true,
      };
    }
    case actions.CLOSE_MODEL_28: {
      return {
        ...state,
        isOpen28: false,
      };
    }
    case actions.OPEN_MODEL_29: {
      return {
        ...state,
        isOpen29: true,
      };
    }
    case actions.CLOSE_MODEL_29: {
      return {
        ...state,
        isOpen29: false,
      };
    }
    case actions.OPEN_MODEL_30: {
      return {
        ...state,
        isOpen30: true,
      };
    }
    case actions.CLOSE_MODEL_30: {
      return {
        ...state,
        isOpen30: false,
      };
    }
    case actions.OPEN_MODEL_32: {
      return {
        ...state,
        isOpen32: true,
      };
    }
    case actions.CLOSE_MODEL_32: {
      return {
        ...state,
        isOpen32: false,
      };
    }
    default:
      {
        return state;
      }
      break;
  }
}
