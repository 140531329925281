import { Container, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import { FiFileText } from "react-icons/fi";
import { GiTeacher } from "react-icons/gi";
import { RiFileCopy2Line } from "react-icons/ri";

const RecordesComp = ({ t }) => {
  const data = [
    {
      icon: <GiTeacher fontSize="38px" color="#e9b959" />,
      title: t("components.records.title_1"),
      desc: t("components.records.desc_1"),
    },
    {
      icon: <FiFileText fontSize="38px" color="#e9b959" />,
      title: t("components.records.title_2"),
      desc: t("components.records.desc_2"),
    },
    {
      icon: <RiFileCopy2Line fontSize="38px" color="#e9b959" />,
      title: t("components.records.title_3"),
      desc: t("components.records.desc_3"),
    },
  ];
  return (
    <Container maxW={"container.xl"} my="10rem">
      <Text
        textAlign="center"
        fontWeight="500"
        fontSize={{ base: "20px", md: "25px" }}
        mb={3}
      >
        {t("components.records.title")}{" "}
        <Image src={require("../../../assets/line.jpg")} m="auto" mt={3} />
      </Text>

      <Stack flexDir="row" flexWrap="wrap" gap={5} mt={8}>
        {data?.map((elem, index) => {
          return (
            <Stack
              bg="#e4e8eb80"
              borderRadius="20px"
              align="center"
              key={index}
              py="60px"
              px="20px"
              w={{ base: "100%", md: "32%" }}
              textAlign="center"
              mt={2}
              boxShadow="0px 1px 4px #8080804d"
            >
              {elem?.icon}

              <Text fontSize="18px" fontWeight="500" fontFamily="Cairo">
                {elem?.title}
              </Text>
              <Text color="#727586" fontSize="14px" fontFamily="Cairo">
                {elem?.desc}
              </Text>
            </Stack>
          );
        })}
      </Stack>
    </Container>
  );
};

export default withTranslation()(RecordesComp);
