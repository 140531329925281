import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Image,
  Stack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import { GiEarthAmerica } from "react-icons/gi";
import { HiMenuAlt2 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
const MobileNavbar = ({ t, i18n }) => {
  const routes = [
    { label: t("components.home_navbar.contact_us"), link: "#callCenter" },
    { label: t("components.home_navbar.subjects"), link: "#subjects" },
    { label: t("components.home_navbar.teachers"), link: "#teachers" },
    { label: t("components.home_navbar.home"), link: "/" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();

  return (
    <>
      <Button
        ref={btnRef}
        colorScheme="teal"
        onClick={onOpen}
        display={{ base: "flex", lg: "none" }}
        alignItems="center"
        as={IconButton}
        aria-label="Options"
        icon={<HiMenuAlt2 />}
        variant="outline"
        color="white"
        border="0"
        _hover={{
          background: "rgb(32, 34, 37)",
        }}
        bg="rgb(32, 34, 37)"
      ></Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            {" "}
            <Stack alignItems="center">
              <Image
                src={require("../../../assets/logo.png")}
                w="200px"
                id="logo"
                h="auto"
                mx="-10px"
                onClick={() => navigate("/")}
              />
            </Stack>
          </DrawerHeader>

          <DrawerBody>
            <VStack p="5px">
              {routes?.map((elem, index) => {
                return (
                  <a key={index} href={elem.link}>
                    {elem?.label}
                  </a>
                );
              })}

              <Button
                onClick={() => navigate("/login")}
                borderColor="main_2"
                bg="main_2"
                textTransform={"capitalize"}
                color="white"
              >
                {t("login")}{" "}
              </Button>

              <IconButton
                icon={<GiEarthAmerica />}
                borderColor="main_2"
                color="main_2"
                variant="outline"
                onClick={() => {
                  const lang = localStorage.getItem("lang");

                  if (lang === "ar") {
                    localStorage.setItem("lang", "en");
                  } else {
                    localStorage.setItem("lang", "ar");
                  }
                  window.location.reload();
                }}
              />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default withTranslation()(MobileNavbar);
