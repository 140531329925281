import { Container, Flex, Text, chakra } from "@chakra-ui/react";
import React from "react";

import { withTranslation } from "react-i18next";
const Header = ({ t }) => {
  return (
    <Flex
      justifyContent="space-between"
      color="white"
      id="home"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      position="relative"
    >
      <Container maxW={"container.xl"}>
        <Flex
          alignItems={"center"}
          justifyContent="center"
          flexDir="column"
          color="black"
          my={10}
        >
          <Text
            fontSize={{ base: "25px", md: "50px" }}
            fontWeight="700"
            mb="1.25rem"
            align="center"
          >
            {t("components.header.desc_1")}
            <chakra.span color="main_2">
              {" "}
              {t("components.header.success")}
            </chakra.span>{" "}
          </Text>
          <Text
            textAlign="center"
            fontSize={{ base: "16px", md: "20px" }}
            wordBreak="break-word"
          >
            {t("components.header.desc_2")}
            <br /> {t("components.header.desc_3")}
          </Text>
        </Flex>
      </Container>
    </Flex>
  );
};

export default withTranslation()(Header);
