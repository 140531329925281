import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { theme } from "./chakra.config";
import Routers from "./components/Routes";
import i18n from "./i18n";
import { getUserDataRequest } from "./modules/user/Actions";

function App() {
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  useEffect(() => {
    if (token) dispatch(getUserDataRequest());
  }, [token]);
  return (
    <I18nextProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <ToastContainer />
        <Routers />
      </ChakraProvider>
    </I18nextProvider>
  );
}

export default App;
