import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { RiUserLine } from "react-icons/ri";
import { logoutUserAction } from "../../../modules/auth/Actions";
import { useDispatch } from "react-redux";
import { openUserDataModel } from "../../../modules/admin-modules/models/Actions";

import logout from "../../../assets/icons/logout.svg";
import MobileNavbar from "./mobileNavbar";
import { useSelector } from "react-redux";
import { onLanguageHandle } from "../../../helpers/language";
import { withTranslation } from "react-i18next";

const Navbar = ({ color, bg, t, i18n }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);
  window.onscroll = function () {
    scrollFn();
  };
  function scrollFn() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("navbar").style.backgroundColor = "white";
    } else {
      document.getElementById("navbar").style.backgroundColor = "";
    }
  }

  return (
    <Flex
      id="navbar"
      position={"fixed"}
      zIndex="100"
      alignItems="center"
      justifyContent="space-between"
      top="0"
      p="5px"
      w="100%"
      color={color}
      bg={bg}
      h="75px"
      textTransform={"capitalize"}
    >
      <Box w={"fit-content"}>
        <Image
          src={require("../../../assets/logo.png")}
          w="200px"
          id="logo"
          h="auto"
          mx="-10px"
          onClick={() => navigate("/home")}
        />{" "}
      </Box>
      <HStack
        spacing="20px"
        display={{ base: "none", md: "flex" }}
        fontSize="16px"
        fontWeight="400"
      >
        <Link to={"/home"}>
          <Text
            _hover={{ color: "main_2" }}
            textDecoration={
              window.location.pathname == "/home" ? "underline" : "none"
            }
            fontWeight={window.location.pathname == "/home" ? "600" : "400"}
            color={window.location.pathname == "/home" ? "main_2" : "black"}
          >
            {t("home")}
          </Text>
        </Link>

        <Link to={"/videos/sections"}>
          <Text
            _hover={{ color: "main_2" }}
            textDecoration={
              window.location.pathname.includes("videos") ||
              window.location.pathname.includes("video")
                ? "underline"
                : "none"
            }
            fontWeight={
              window.location.pathname.includes("videos") ||
              window.location.pathname.includes("video")
                ? "600"
                : "400"
            }
            color={
              window.location.pathname.includes("videos") ||
              window.location.pathname.includes("video")
                ? "main_2"
                : "black"
            }
          >
            {t("videos")}
          </Text>
        </Link>
        <Link to={"/model-answers/chapters"}>
          <Text
            _hover={{ color: "main_2" }}
            textDecoration={
              window.location.pathname.includes("model-answer") ||
              window.location.pathname.includes("model-answers")
                ? "underline"
                : "none"
            }
            fontWeight={
              window.location.pathname.includes("model-answer") ||
              window.location.pathname.includes("model-answers")
                ? "600"
                : "400"
            }
            color={
              window.location.pathname.includes("model-answer") ||
              window.location.pathname.includes("model-answer")
                ? "main_2"
                : "black"
            }
          >
            PDF
          </Text>
        </Link>
        <Link to={"/homeworks/sections"}>
          <Text
            _hover={{ color: "main_2" }}
            textDecoration={
              window.location.pathname.includes("homeworks") ||
              window.location.pathname.includes("homework")
                ? "underline"
                : "none"
            }
            fontWeight={
              window.location.pathname.includes("homeworks") ||
              window.location.pathname.includes("homework")
                ? "600"
                : "400"
            }
            color={
              window.location.pathname.includes("homeworks") ||
              window.location.pathname.includes("homework")
                ? "main_2"
                : "black"
            }
          >
            {t("homework")}
          </Text>
        </Link>
        <Link to={"/exams/sections"}>
          <Text
            _hover={{ color: "main_2" }}
            textDecoration={
              !window.location.pathname.includes("student") &&
              (window.location.pathname.includes("exams") ||
                window.location.pathname.includes("exam"))
                ? "underline"
                : "none"
            }
            fontWeight={
              !window.location.pathname.includes("student") &&
              (window.location.pathname.includes("exams") ||
                window.location.pathname.includes("exam"))
                ? "600"
                : "400"
            }
            color={
              !window.location.pathname.includes("student") &&
              (window.location.pathname.includes("exams") ||
                window.location.pathname.includes("exam"))
                ? "main_2"
                : "black"
            }
          >
            {t("exams")}
          </Text>
        </Link>
        <Link to={"/asquera-practice"}>
          <Text
            _hover={{ color: "main_2" }}
            textDecoration={
              window.location.pathname.includes("asquera-practice")
                ? "underline"
                : "none"
            }
            fontWeight={
              window.location.pathname.includes("asquera-practice")
                ? "600"
                : "400"
            }
            color={
              window.location.pathname.includes("asquera-practice")
                ? "main_2"
                : "black"
            }
          >
            Asquera Practice
          </Text>
        </Link>
        {/* <Link to={"/questions"}>
          <Text
            _hover={{ color: "main_2" }}
            textDecoration={
              window.location.pathname.includes("Missed") ? "underline" : "none"
            }
            fontWeight={
              window.location.pathname.includes("Missed") ? "600" : "400"
            }
            color={
              window.location.pathname.includes("Missed") ? "main_2" : "black"
            }
          >
            Missed questions
          </Text>{" "}
        </Link> */}
        {/* 
        {userData?.subject?.id == 1 && (
          <Link to={"/student/exams"}>
            <Text
              _hover={{ color: "main_2" }}
              textDecoration={
                window.location.pathname.includes("student") &&
                window.location.pathname.includes("exams")
                  ? "underline"
                  : "none"
              }
              fontWeight={
                window.location.pathname.includes("student") &&
                window.location.pathname.includes("exams")
                  ? "600"
                  : "400"
              }
            >
              {t("student_navbar.studentExams")}
            </Text>
          </Link>
        )}
        <Text _hover={{ color: "main_2" }}>
          {t("student_navbar.summaries")}
        </Text>

        <Text _hover={{ color: "main_2" }}>
          {t("student_navbar.liveVideos")}
        </Text>

        <Text _hover={{ color: "main_2" }}>
          {t("student_navbar.studyWithasquera")}
        </Text> */}
      </HStack>

      <HStack display={{ base: "none", md: "flex" }} mx={2}>
        <Select
          dir={localStorage.getItem("language") == "ar" ? "rtl" : "ltr"}
          variant="unstyled"
          width="120px"
          color="main_2"
          value={localStorage.getItem("language") || "en"}
          onChange={(e) => onLanguageHandle(e.target.value, i18n)}
        >
          <option style={{ textAlign: "center" }} value="ar">
            عربي
          </option>
          <option value="en" style={{ textAlign: "center" }}>
            En
          </option>
        </Select>
        <Menu direction="rtl">
          <MenuButton
            _focus={{ outline: "none" }}
            as={IconButton}
            borderColor="main_2"
            aria-label="Options"
            icon={<RiUserLine color="#f7d723" fontSize="20px" />}
            variant="outline"
          />
          <MenuList dir="rtl">
            <MenuItem p="7px" mt="10px">
              <HStack spacing="7px">
                <Text mx="5px">{userData?.name}</Text>
              </HStack>
            </MenuItem>
            <MenuItem p="7px" mt="10px">
              <HStack spacing="7px">
                <Text mx="5px">{userData?.phone} </Text>
              </HStack>
            </MenuItem>
            <MenuItem p="7px" mt="10px">
              <HStack spacing="7px">
                <Text mx="5px">{userData?.grade?.name} </Text>
              </HStack>
            </MenuItem>
            <MenuItem
              p="7px"
              mt="10px"
              onClick={() => dispatch(openUserDataModel())}
            >
              <HStack spacing="7px">
                <Text mx="5px">QR Code</Text>
              </HStack>
            </MenuItem>
            <MenuDivider />

            <MenuItem
              p="7px"
              mt="10px"
              onClick={() => dispatch(logoutUserAction())}
            >
              <HStack spacing="7px">
                <Image src={logout} minW="15px" />
                <Text mx="5px">تسجيل الخروج</Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      <Box display={{ base: "block", md: "none" }}>
        <MobileNavbar />
      </Box>
    </Flex>
  );
};

export default withTranslation()(Navbar);
